import UserContactDataStorageService, {UserContactDataType} from "../../services/UserContactDataStorageService";
import GoogleTrackingFactory from "../../core/tracking/GoogleTrackingFactory";
import {Brand} from "../../types/Brand";
import {Country} from "../../types/Country";
import FeatureFlags from "../../FeatureFlags";
import CookieService from "../../services/CookieService";

let showGoogleOneTapCookieName = '_showGoogleOneTapWL';

interface GoogleUserAuth {
    credential: string,
}

interface GoogleUser {
    name: string,
    email: string,
}

//https://developers.google.com/identity/gsi/web/reference/js-reference#PromptMomentNotification
interface PromptMomentNotification {
    isDisplayMoment: () => boolean,
    isDisplayed: () => boolean,
    isNotDisplayed: () => boolean,
    getNotDisplayedReason: () => NotDisplayedReason,
    isSkippedMoment: () => boolean,
    getSkippedReason: () => SkippedReason,
    isDismissedMoment: () => boolean,
    getDismissedReason: () => DismissedReason,
    getMomentType: () => MomentType,
}

type Reason = NotDisplayedReason | SkippedReason | DismissedReason;

enum NotDisplayedReason {
    BROWSER_NOT_SUPPORTED = 'browser_not_supported',
    INVALID_CLIENT = 'invalid_client',
    MISSING_CLIENT_ID = 'missing_client_id',
    OPT_OUT_OR_NO_SESSION = 'opt_out_or_no_session',
    SECURE_HTTP_REQUIRED = 'secure_http_required',
    SUPPRESSED_BY_USER = 'suppressed_by_user',
    UNREGISTERED_ORIGIN = 'unregistered_origin',
    UNKNOWN_REASON = 'unknown_reason',
}

enum SkippedReason {
    AUTO_CANCEL = 'auto_cancel',
    USER_CANCEL = 'user_cancel',
    TAP_OUTSIDE = 'tap_outside',
    ISSUING_FAILED = 'issuing_failed',
}

enum DismissedReason {
    CREDENTIAL_RETURNED = 'credential_returned',
    CANCEL_CALLED = 'cancel_called',
    FLOW_RESTARTED = 'flow_restarted',
}

enum MomentType {
    DISPLAY = 'display',
    SKIPPED = 'skipped',
    DISMISSED = 'dismissed',
}

enum GoogleOneTapEventType {
    display = 'display',
    displayed = 'displayed',
    notDisplayed = 'not_displayed',
    skipped = 'skipped',
    dismissed = 'dismissed',
    logged = 'logged',
}

function onGoogleTapSignInWL(googleUserAuth: GoogleUserAuth) {
    const user = parseJwt(googleUserAuth.credential);
    const storage = new UserContactDataStorageService()
    storage.storeUserValue(UserContactDataType.NAME, user.name);
    storage.storeUserValue(UserContactDataType.EMAIL, user.email);

    setCookie(showGoogleOneTapCookieName, 'true', 2 * 60 * 60 * 1000);
    sendGA4LoggedEvent();
    location.reload();
}

function parseJwt(token: string): GoogleUser {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function setCookie(name: string, value: string, expirationTimeInMs: number) {
    let date = new Date();
    date.setTime(date.getTime() + expirationTimeInMs);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; Path=/";
}

function sendGA4LoggedEvent() {
    sendGA4Event(GoogleOneTapEventType.logged);
}

function onGoogleTapCloseWL() {
    sendGA4Event(GoogleOneTapEventType.skipped, SkippedReason.USER_CANCEL);
}

function logMomentNotificationWL(notification: PromptMomentNotification) {
    let featureFlags = new FeatureFlags();
    if (featureFlags.isActiveFeature("printConsoleDebug")) {
        console.info('Google One Tap notification', JSON.stringify({
            isDisplayMoment: notification.isDisplayMoment(),
            isDisplayed: notification.isDisplayed(),
            isNotDisplayed: notification.isNotDisplayed(),
            getNotDisplayedReason: notification.getNotDisplayedReason(),
            isSkippedMoment: notification.isSkippedMoment(),
            getSkippedReason: notification.getSkippedReason(),
            isDismissedMoment: notification.isDismissedMoment(),
            getDismissedReason: notification.getDismissedReason(),
            getMomentType: notification.getMomentType(),
        }));
    }
    if (notification.isDisplayed()) {
        sendGA4Event(GoogleOneTapEventType.displayed);
    }
    if (notification.isNotDisplayed()) {
        sendGA4Event(GoogleOneTapEventType.notDisplayed, notification.getNotDisplayedReason());
    }
    if (notification.isSkippedMoment()) {
        sendGA4Event(GoogleOneTapEventType.skipped, notification.getSkippedReason());
    }
    if (notification.isDismissedMoment()) {
        sendGA4Event(GoogleOneTapEventType.dismissed, notification.getDismissedReason());
    }
}

function sendGA4Event(name: GoogleOneTapEventType, reason?: Reason) {
    const eventName = `wl_got_${name}`;
    const googleOneTapContainer = document.getElementById('g_id_onload');
    const brand = googleOneTapContainer?.dataset.brand as Brand;
    const country = googleOneTapContainer?.dataset.country as Country;
    const googleTracking = GoogleTrackingFactory.createFor(brand, country);
    const otherArguments = !reason ? {} : {'got_reason': reason};

    let featureFlags = new FeatureFlags();
    if (featureFlags.isActiveFeature("printConsoleDebug")) {
        // @ts-ignore
        console.log(`DataLayer: ${JSON.stringify(dataLayer)}`)
    }
    googleTracking.trackEvent(eventName, otherArguments);
}

(window as any).onGoogleTapSignInWL = onGoogleTapSignInWL;
(window as any).logMomentNotificationWL = logMomentNotificationWL;
(window as any).onGoogleTapCloseWL = onGoogleTapCloseWL;

function hasCookie() {
    return new CookieService().getCookie(showGoogleOneTapCookieName) != undefined;
}

window.onload = function () {
    // @ts-ignore
    if(!google || hasCookie()) return;

    // @ts-ignore
    google.accounts.id.prompt((notification) => logMomentNotificationWL(notification));
};