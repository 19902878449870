import * as Cookies from 'js-cookie';

interface CookieOptions {
  expires?: number
}

class CookieService {
  private cookieProperties: CookieOptions = {};

  public setCookie = (cookieName: string, cookieValue: string) => Cookies.set(cookieName, cookieValue, this.cookieProperties);

  public getCookie = (cookieName: string) => Cookies.get(cookieName);

  public setCookieExpirationDate = (days: number) => {
    this.cookieProperties.expires = days;
    return this
  };

  static exists = (cookieName: string) => Cookies.get(cookieName) !== undefined;
}

export default CookieService;
