export default class FeatureFlags {
    isActiveFeature = (featureName: string): boolean => {
        const urlParams = new URLSearchParams(window.location.search);
        const feature = Boolean(urlParams.get(featureName))
        if (feature) {
            return true;
        }
        const cookie = document.cookie;
        return cookie.includes(featureName);
    }
};